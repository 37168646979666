import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['selectedActionSuggestionInput', 'suggestionDisplayFrame']

  select() {
    event.preventDefault();
    let selectedActionSuggestionCard = event.currentTarget
    let actionSuggestionId = selectedActionSuggestionCard.id
    const frame = this.suggestionDisplayFrameTarget
    let url;

    this.selectedActionSuggestionInputTargets.forEach(input => {
      if (input.value == actionSuggestionId) {
        url =  frame.dataset.displayPlaceholderUrl
        input.value = ''
      } else {
        url = selectedActionSuggestionCard.href
        input.value = actionSuggestionId
      }
        frame.src = url
        frame.reload()
    })
  }
}
