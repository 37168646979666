import { Controller } from "stimulus"
import { post } from '@rails/request.js'

export default class extends Controller {
  static targets = ["selectedActivityDataInput",
                    "selectableActivityDatumInput",
                    "counter",
                    "bottomSheet",
                    "deletionLink",
                    "editionLink",
                    "activityDataTable",
                    "emissionsSum",
                    "headerCheckbox"]

  static values = {
    counterUrl: String
  }

  get selectedActivityData() { return this.hasSelectedActivityDataInputTarget ? this.selectedActivityDataInputTarget.value.split(',') : [] };
  get selectedActivityDataCount() { return this.compactArray(this.selectedActivityData).length }
  get anySelectedActivityData() { return this.selectedActivityDataCount > 0 }

  connect() {
    this.setHeaderCheckboxState()
  }

  selectedActivityDataInputTargetConnected(element) {
    this.handleNewSelection(this.selectedActivityData)
  }

  toggleTableCheckboxes(event) {
    const checked = event.currentTarget.checked
    const table = document.querySelector(".table-body-js")

    table.querySelectorAll("input[type='checkbox'").forEach(checkbox => { if (!checkbox.disabled) checkbox.checked = checked })
  }

  clickCheckbox(event) {
    const checkbox = event.currentTarget
    let selectedActivityData = this.selectedActivityData
    if (checkbox.checked) {
      selectedActivityData.push(checkbox.value)
    } else {
      selectedActivityData = selectedActivityData.filter(item => item !== checkbox.value.toString())
    }
    this.handleNewSelection(selectedActivityData)
    this.setHeaderCheckboxState()
  }

  clickHeaderCheckbox(event) {
    const headerCheckbox = event.currentTarget
    let newSelection;
    const selectedActivityData = this.selectedActivityData
    const selectableActivityData = this.selectableActivityDatumInputTargets.map(adInput => adInput.value)
    if (headerCheckbox.checked) {
      newSelection = selectedActivityData.concat(selectableActivityData.filter((item) => selectedActivityData.indexOf(item) < 0))
    } else {
      newSelection = selectedActivityData.filter(item => selectableActivityData.indexOf(item) == -1)
    }
    this.handleNewSelection(newSelection)
  }

  defineBottomSheetWidth() {
    if (!this.hasActivityDataTableTarget || !this.hasBottomSheetTarget) { return }

    const activityDataTableWidth = `${this.activityDataTableTarget.clientWidth}px`

    this.bottomSheetTarget.style.width = activityDataTableWidth
  }

  setHeaderCheckboxState() {
    if (!this.hasHeaderCheckboxTarget) { return }

    const selectableActivityData = this.selectableActivityDatumInputTargets.map(adInput => adInput.value)
    const selectedActivityData = this.selectedActivityData

    if (this.allSelectableAreSelected(selectableActivityData, selectedActivityData) && selectedActivityData.length != 0 ) {
      this.headerCheckboxTarget.indeterminate = false;
      this.headerCheckboxTarget.checked = true
    } else if (this.someSelectableAreSelected(selectableActivityData, selectedActivityData)) {
      this.headerCheckboxTarget.indeterminate = true;
    } else {
      this.headerCheckboxTarget.indeterminate = false;
      this.headerCheckboxTarget.checked = false
    }
  }

  // private

  allSelectableAreSelected = (selectable, selected) => selectable.every(id => selected.includes(id));
  someSelectableAreSelected = (selectable, selected) => selectable.some(id => selected.includes(id));

  handleNewSelection(newSelection) {
    this.updateSelectedActivityData(newSelection)
    this.updatePaginationParams()
    this.updateDeletionParams()
    this.updateEditionParams()
    this.updateBatchActionsBottomSheet()
  }

  updateSelectedActivityData(newSelection) {
    this.selectedActivityDataInputTarget.value = this.compactArray(newSelection).join(',')
  }

  updateBatchActionsBottomSheet() {
    if (this.anySelectedActivityData) {
      this.updateCounterAndDisplay()
    } else {
      this.hideBottomSheet()
    }
  }

  displayBottomSheet() {
    this.defineBottomSheetWidth()
    this.bottomSheetTarget.style.visibility = "visible"
  }

  hideBottomSheet() {
    if (!this.hasBottomSheetTarget) { return }

    this.bottomSheetTarget.style.visibility = "hidden"
  }

  async updateCounterAndDisplay() {
    const params = { activity_data_ids: this.selectedActivityData }
    const response = await post(this.counterUrlValue, { responseKind: "turbo-stream", body: JSON.stringify(params) })

    if (response.ok) {
      this.displayBottomSheet()
    }
  }

  updatePaginationParams() {
    const paginationFrame = document.querySelector("nav.pagination")
    if (paginationFrame) {
      const paginationLinks = paginationFrame.querySelectorAll('a')
      paginationLinks.forEach(link => this.updateSelectedActivityDataLink(link))
    }
  }

  updateDeletionParams() {
    if (!this.hasDeletionLinkTarget) { return }

    this.updateSelectedActivityDataLink(this.deletionLinkTarget)
  }

  updateEditionParams() {
    this.editionLinkTargets.forEach(editionLink => this.updateSelectedActivityDataLink(editionLink))
  }

  updateSelectedActivityDataLink(link) {
    const activityDataIds = this.selectedActivityDataInputTarget.value
    const url = new URL(link.href);
    url.searchParams.delete('activity_data_ids')
    url.searchParams.append('activity_data_ids', activityDataIds);
    link.href = url.href
  }

  compactArray(array) { return array.filter(element => { return element !== '' }) }
}
