import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input"]
  static outlets = ["v2--table-component--selectable-table"]

  connect() {
    const selectedItemsIds = this.v2TableComponentSelectableTableOutlet.selectedItemsIdsValue

    this.inputTargets.forEach((input) => {
      input.value = selectedItemsIds.join(",")
    })
  }
}
