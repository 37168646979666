import { Controller } from "stimulus";
import { fetchWithToken} from "~/utils/fetch_with_token"

export default class extends Controller {
  static targets = ["fileInput", "submit"];

  enableSubmit() {
    this.submitTarget.disabled = !this.valid()
  }

  disableSubmit() {
    this.submitTarget.disabled = true
  }

  submitForm(event) {
    event.preventDefault();

    const url = this.submitTarget.dataset.submitUrl
    const file = this.fileInputTarget.files[0]
    const formData = new FormData()

    formData.append('file', file)

    this.disableSubmit()

    fetchWithToken(url, {
      method: 'post',
      body: formData
    })
  }

  // private

  valid = () => { return this.fileInputTarget.files.length > 0 }
}
