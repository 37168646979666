import { Controller } from "stimulus"
import { throttle } from 'lodash';

export default class extends Controller {
  static targets = ["select", "detailRow", "detailBlock", "shareCounter", "emissionShareInput"]

  initialize() {
    this.toggleDetailRow = throttle(this.toggleDetailRow, 200)
  }

  deselect(event) {
    const entityId = event.currentTarget.dataset.entityId
    this.#deselectEntity(entityId)
    this.#toggleDetailRowVisibility(entityId, false)
    this.setShareCounters()
    this.#toggleDetailBlockVisibility()
  }

  toggleDetailRow() {
    const checkboxes = this.element.querySelectorAll("input[type=checkbox]")
    checkboxes.forEach(checkbox => {
      const entityId = checkbox.value
      this.#toggleDetailRowVisibility(entityId, checkbox.checked)
    })
    this.setShareCounters()
    this.#toggleDetailBlockVisibility()
  }

  setShareCounters() {
    const totalShares = this.#calculateTotalShares()
    this.shareCounterTargets.forEach(counter => {
      const entityId = counter.dataset.entityId
      const detailRow = this.#findDetailRow(entityId)
      const emissionShareInput = this.#emissionShareInput(detailRow)
      counter.innerText = this.#calculateShareCounter(emissionShareInput.value, totalShares)
    })
   }

   selectInputContent(event) {
    const input = event.currentTarget

    input.select()
   }

  #deselectEntity(entityId) {
    const checkbox = this.selectTarget.querySelector(`input#checkbox-${entityId}`)
    if (checkbox) {
      checkbox.checked = false
      checkbox.dispatchEvent(new Event("change", { bubbles: true }))
    }
  }

  #toggleDetailRowVisibility(entityId, isVisible) {
    const detailRow = this.#findDetailRow(entityId)
    if (detailRow) {
      if (isVisible) {
        this.#showDetailRow(detailRow)
      } else {
        this.#hideDetailRow(detailRow)
      }
    }
  }

  #showDetailRow(detailRow) {
    detailRow.classList.remove("hidden")
    this.#setDefaultEmissionShareInput(detailRow)
  }

  #hideDetailRow(detailRow) {
    detailRow.classList.add("hidden")
    this.#resetEmissionShareInput(detailRow)
  }

  #findDetailRow(entityId) {
    return this.detailRowTargets.find(row => row.dataset.entityId === entityId)
  }

  #resetEmissionShareInput(detailRow) {
    const emissionShareInput = this.#emissionShareInput(detailRow)
    emissionShareInput.value = ""
  }

  #setDefaultEmissionShareInput(detailRow) {
    const emissionShareInput = this.#emissionShareInput(detailRow)
    if (emissionShareInput.value === "") {
        emissionShareInput.value = 1
    }
  }

  #emissionShareInput(detailRow) {
    return detailRow.querySelector(`input[type="number"]`)
  }

  #toggleDetailBlockVisibility() {
    const visibleDetailRows = this.detailRowTargets.filter(row => !row.classList.contains("hidden"))
    const isVisible = visibleDetailRows.length > 1
    this.detailBlockTarget.classList.toggle("hidden", !isVisible)
  }

  #calculateShareCounter(emissionShare, totalShares) {
    if (totalShares === 0) return '0.0 %'

    let emissionShareRatio = (emissionShare / totalShares) * 100

    if (emissionShareRatio < 1) {
      return `${emissionShareRatio.toFixed(1)}%`
    }

    return `${Math.round(emissionShareRatio)}%`
  }

  #calculateTotalShares() {
    return this.emissionShareInputTargets.reduce((totalShares, input) => {
        if (input.value === "") return totalShares
        return totalShares + parseFloat(input.value)
      }, 0)
  }
}
