import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["reportDivisionEntitiesSelect", "submit", "reportDivisionEntityInput"]

  get emptySelectedEntity() { return this.reportDivisionEntityInputTarget.value == "" }

  displayReportDivisionEntitiesSelect(event) {
    const reportDivisionId = event.currentTarget.value

    this.hideAllEntitiesSelect()

    if (reportDivisionId) {
      this.revealTargetedEntitiesSelect(reportDivisionId)
    }
  }

  toggleSubmitEnabling() {
    this.submitTarget.disabled = this.emptySelectedEntity
  }

  updateReportDivisionEntityInput(event) {
    this.reportDivisionEntityInputTarget.value = event.currentTarget.value
  }

  // private

  revealTargetedEntitiesSelect(reportDivisionId) {
    const targetedSelect = this.entitySelectByDivisionId(reportDivisionId)
    targetedSelect.classList.remove("d-none");
  }

  hideAllEntitiesSelect() {
    this.reportDivisionEntitiesSelectTargets.forEach(select => {
      this.hideSelect(select)
    })

    this.reportDivisionEntityInputTarget.value = ""
  }

  hideSelect(select) {
    select.value = ""
    select.classList.add("d-none");
  }

  entitySelectByDivisionId(reportDivisionId) {
    return this.reportDivisionEntitiesSelectTargets.find(select => select.dataset.reportDivisionId === reportDivisionId)
  }
}
