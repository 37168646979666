import { Controller } from "stimulus"
import $ from "jquery"

export default class extends Controller {
  static targets = ["field", 'localization', 'unit', "amortization", "base"]
  static values = { emissionItem: String }

  connect() {
    this.toggleLocalizationLabel();
    this.hideEmptyInputs();
    if (location.search.match(/preload/)) {
      this.preloadDropdowns()
    }
  }

  handleSelect(event) {
    this.loadData(event.target);
  }

  loadData(element) {
    // Get path and index stored in field data attribute
    const path = element.dataset.path || "/emission_factors.json";
    const target = element.dataset.targetAttribute;
    // Stop if either no path, no target, or if current element is the only unit field and there is no amortization field
    if (!path || !target ||
      (Object.values(element.dataset).includes("unit_1")) && this.unitTargets.length === 1 && !this.hasAmortizationTarget) {
      return;
    }
    // Clear fields after the selected one
    this.clearFieldsAfterElement(element);
    // Fetch options
    this.fetchOptions(path, element);
  }

  fetchOptions = (url, element) => {
    const that = this;
    $.ajax({
      url: url,
      async: false,
      dataType: 'json',
      type: 'GET',
      headers: { 'X-CSRF-Token':  document.querySelector('meta[name="csrf-token"]')?.content },
      data: this.buildData(element),
      success: (response) => {
        const nextInput = that.nextInput(element);
        if (!nextInput) {
          return;
        } else if (this.hasAmortizationTarget && nextInput === this.amortizationTarget) {
          nextInput.value = response[0][0] === "-" ? "" : response[0][0];
        } else if (nextInput && response.length > 1 && response[0] !== "-") {
          this.replaceOptions(element, nextInput, response)
          this.reveal(nextInput);
        } else if (nextInput && response[0] !== "-") {
          // Set the unique option
          this.replaceOptions(element, nextInput, response);
          // don't display empty "-" input
          if (response.length === 0 || response[0].includes("-")) {
            this.hide(nextInput);
          } else {
            this.reveal(nextInput);
          }
          this.loadData(nextInput);
        } else {
          this.loadData(nextInput);
        }
      }
    });
  }

  replaceOptions(element = null, nextInput, options) {
    // Insert placeholder
    const optionsWithPlaceholder = this.addPlaceholder(element, nextInput, options);
    // Don't replace if options are the same
    if (JSON.stringify([...nextInput.options].map(option => option.innerText)) !== JSON.stringify(optionsWithPlaceholder)) {
      const html = this.buildOptions(optionsWithPlaceholder);
      nextInput.innerHTML = html;
    }
  }

  addPlaceholder = (element = null, nextInput, options) => {
    const placeholder = nextInput.dataset.placeholder;
    if (placeholder && placeholder !== "false" && options.length > 1) {
      options.unshift(placeholder);
    }
    return options;
  }

  buildOptions(results) {
    return results.filter(result => result != null).map(result => {
      // le placeholder est un string :
      if (typeof(result) === "string") return `<option value>${result}</option>`
      // pour les autres options, on a un array avec la valeur dans la locale en 0, et la
      // valeur :fr en 1
      return `<option value="${result[1]}">${result[0]}</option>`
    }).join('\n');
  }

  buildData(element) {
    const data = {}
    this.fieldTargets.forEach(field => data[`ef_level_${field.dataset.index}`] = field.value);
    this.localizationTargets.forEach(localization => data[`localization_${localization.dataset.index}`] = localization.value);
    data['db_name'] = this.emissionItemValue;
    // TODO: Set this dynamically thanks to data-index and data-target
    data['target_attribute'] = element.dataset.targetAttribute;

    return { 'emission_factor': data};
  }

  clearFieldsAfterElement = (element) => {
    const efFields = [...this.fieldTargets, ...this.localizationTargets, ...this.unitTargets]

    if (!efFields.includes(element)) return

    const elementIndex = efFields.findIndex(field => field === element)
    const fieldsAfterElement = efFields.slice(elementIndex + 1)
    const lastField = efFields[efFields.length - 1]

    fieldsAfterElement.forEach(field => {
      this.clearInput(field)
      const isLast = field === lastField
      if (!isLast) {
        this.hide(field)
      }
    })
  }

  clearInput(field) {
    field.options.length = 0;
    field.innerHTML = '<option selected value="-">-</option>';
  }

  nextInput(element) {
    // Find the next input based on an element's target_attribute dataset.
    const targetAttribute = element.dataset.targetAttribute;
    if (!element.dataset.targetAttribute) {
      return null;
    } else if (element.dataset.targetAttribute === "amortization_duration") {
      return this.amortizationTarget;
    }
    const elementName = targetAttribute.match(/(?<type>\w*)_(?<index>\d)/).groups
    const elementType = elementName.type;
    const index = elementName.index;
    switch (elementType) {
      case "ef_level":
        return this.fieldTargets.find(target => target.dataset.index === index);
      case "localization":
        return this.localizationTargets.find(target => target.dataset.index === index);
      case "data_unit":
        return this.unitTargets.find(target => target.dataset.index === index);
      default:
        return null;
    }
  }

  reveal(element) {
    element.parentNode.parentNode.classList.remove('d-none');
    this.toggleLocalizationLabel();
  }

  hide = (element) => {
    // Never hide a unit field
    if (!this.unitTargets.includes(element)) {
      element.parentNode.parentNode.classList.add('d-none');
    }
    this.toggleLocalizationLabel();
  }

  toggleLocalizationLabel() {
    const label = document.getElementById('geography-label');
    if (!label) return;
    if (this.localizationTargets.every(input => input.options.length <= 1 || input.value === "-" )) {
      label.classList.add('d-none');
    } else {
      label.classList.remove('d-none');
    }
  }

  hideEmptyInputs() {
    const targets = [...this.localizationTargets, ...this.fieldTargets];
    const emptyTargets = targets.filter(t => (t.value === "-"));
    emptyTargets.forEach(target => this.hide(target))
  }

  preloadDropdowns() {
    const searchParams = new URLSearchParams(location.search);
    searchParams.sort();
    for (const [attribute, value] of searchParams.entries()) {
      [this.fieldTargets, this.localizationTargets, this.unitTargets, this.amortizationTargets].forEach(targets => {
        const fieldTarget = targets.find(field => field.name === `activity_datum[${attribute}]`)
        if (fieldTarget) {
          const option = Array.from(fieldTarget.options).find(option => option.value === value)
          if (option) {
            option.selected = true;
            fieldTarget.dispatchEvent(new Event('change'));
          } else {
            searchParams.append(attribute, value)
          }
        }
      })
    }
  }

  handleReportYear = (nextInput) => {
    if (nextInput.name !== this.yearInput) return

    const options = nextInput.options;
    if ([...options].map(option => option.value).includes(this.reportYearValue)) {
      nextInput.value = this.reportYearValue;
    } else {
      nextInput.value = options[options.length - 1].value
    }
    nextInput.dispatchEvent(new Event('change'));
  }

  replaceOptionsAndHandleReportYear = (element = null, nextInput, options) => {
    if (JSON.stringify([...nextInput.options].map(option => option.innerText)) !== JSON.stringify(options)) {
      const html = this.buildOptions(options);
      nextInput.innerHTML = html;
    }
    this.handleReportYear(nextInput);
  }
}
